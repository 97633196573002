






























import { Component, Prop, Vue } from 'vue-property-decorator'
import DataTableFilter from '@/shared/classes/components/data-table/data-table-filter'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import { FieldTypes } from '@/shared/components/form/field-types'
import { Positions } from '@/shared/interfaces/classes/form.interfaces'
import __ from '@/shared/helpers/__'
import DataTableBase from '@/shared/classes/components/data-table/data-table'

@Component({
  components: { Form },
  methods: {
    __,
  },
})
export default class RowFilters extends Vue {
  @Prop() filters!: DataTableFilter[]
  @Prop() table!: DataTableBase
  @Prop({ default: false }) withoutMenu!: boolean
  activeFilters: number = 0
  open: boolean = false
  form: FormBase = new FormBase()
    .setFormId('filters')
    .setWithoutData(true)
    .setSpacing(true)
    .setInline(this.withoutMenu)
    .setSubmit({
      text: __('components.table.row-filters.submit'),
      color: 'primary',
      position: Positions.right,
      class: 'row-filter-submit',
      depressed: true,
    })
    .setOnSuccess(this.onSuccess)

  mounted() {
    this.parseFiltersToFields()
    this.$watch('$route', this.parseFiltersToFields)
  }

  onSuccess() {
    let { query, name: string } = this.$router.currentRoute
    query = { ...query }
    this.filters.forEach((filter: DataTableFilter) => {
      let value: any = ''
      if (filter.field) {
        if (filter.field.value instanceof Array) {
          value = filter.field.value.join(',')
        } else {
          value = filter.field.value
        }
      }

      if (!filter) return

      if ((!value || value === '') && typeof value !== 'boolean') {
        delete query[filter.getQueryName()]
        return
      }

      query[filter.getQueryName()] = value
    })

    query.page = String(1)

    this.$router.push({ name, query })
      .catch(() => {
      })

    this.open = false
  }

  clear(): void {
    let { query, name: string } = this.$router.currentRoute
    query = { ...query }

    Object.keys(query).forEach((key: string) => {
      if (key.startsWith('filter-')) delete query[key]
    })
    this.$router.push({ name, query })
      .catch(() => {
      })
  }

  private parseFiltersToFields(): void {
    this.activeFilters = 0
    this.form.setFields([])
    this.filters
      .forEach((filter: DataTableFilter) => {
        const { query } = this.$router.currentRoute
        const found = Object.keys(query).find((key: string) => key === filter.getQueryName())
        let value = ''
        if (found && query[found]) {
          this.activeFilters++
          value = query[found] as string

          if (filter.field.type === FieldTypes.multipleSelect || filter.field.type === FieldTypes.multipleSearchable) {
            filter.field.setValue(value.split(','))
          } else {
            filter.field.setValue(value)
          }
        } else {
          filter.field.setValue(null)
        }

        filter.field.setHideDetails(true)
        if (this.withoutMenu) {
          filter.field.setDense(true)
          // ! filter.field.onChange && filter.field.setOnChange(this.onSuccess)
        }

        this.form.addField(filter.field)
      })
  }
}

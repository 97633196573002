import { RoleLevel } from "@/shared/modules/role/role.model";

export default class DataTableAction {
  icon!: string
  title!: string
  color!: string
  component!: any
  action!: (item: any) => void
  permissions!: string[]
  allowedForItem!: (item: any) => boolean
  allowedForRole: RoleLevel[] = []

  setIcon(icon: string): DataTableAction {
    this.icon = icon
    return this
  }

  setTitle(title: string): DataTableAction {
    this.title = title
    return this
  }

  setColor(color: string): DataTableAction {
    this.color = color
    return this
  }

  setComponent(component: any): DataTableAction {
    this.component = component
    return this
  }

  setAction(action: (item: any) => void): DataTableAction {
    this.action = action
    return this
  }

  setPermissions(permissions: string[]): DataTableAction {
    this.permissions = permissions
    return this
  }

  setAllowedForItem(allowedForItem: (item: any) => boolean): DataTableAction {
    this.allowedForItem = allowedForItem
    return this
  }
  
  setAllowedForRole(allowedForRole: RoleLevel[]): DataTableAction {
    this.allowedForRole = allowedForRole
    return this
  }
}












import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'

@Component({
  methods: { __ },
})
export default class SelectedRows extends Vue {
  @Prop() selectedElements!: any[]
}













import { Component, Prop } from 'vue-property-decorator'
import AbstractHeader from '@/shared/components/data-table/header-types/AbstractHeader'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'

@Component
export default class HoverHeader extends AbstractHeader {
  @Prop() props!: any

  get tooltipText(): null|string {
    return this.valueParser?.text
  }

  get color(): null|string {
    return this.valueParser?.color
  }

  get display(): boolean {
    return this.valueParser?.display
  }

  get icon(): string {
    return this.valueParser?.icon || 'mdi-map-marker-radius'
  }

  get valueParser(): any {
    return this.header.valueParser(this.value, this.props.item)
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}






















import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import RowFilters from '@/shared/components/data-table/RowFilters.vue'
import DataTableFilterObject from '@/shared/classes/components/data-table/data-table-filter-object'
import ColumnFilter from '@/shared/components/data-table/ColumnFilter.vue'
import ActiveFilters from '@/shared/components/data-table/ActiveFilters.vue'
import Search from '@/shared/components/data-table/Search.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'

@Component({
  components: { Search, ActiveFilters, ColumnFilter, RowFilters },
  methods: {
    __,
  },
})
export default class Filters extends Vue {
  @Prop() filter!: DataTableFilterObject
  @Prop() headers!: DataTableHeader[]
  @Prop() table!: DataTableBase
  @Prop({ default: false }) rowFilterInHeading!: boolean
  @Prop() refresh!: () => void

  clearActiveFilters(): void {
    const rowFilters: RowFilters | any = this.$refs.rowFilters
    if (rowFilters) rowFilters.clear()
  }
}

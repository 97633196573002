



import { Component } from "vue-property-decorator";
import AbstractHeader from '@/shared/components/data-table/header-types/AbstractHeader'
import { GlobalActions } from "@/shared/store/global/global.actions";
import InfoDialog from "@/shared/components/dialogs/InfoDialog.vue";
import __ from "@/shared/helpers/__";

  @Component
  export default class MultipleCheckboxHeader extends AbstractHeader {
    get selected(): boolean {
      return this.props.item.selected
    }

    onClick() {
      if (this.table.maxSelectAmount && !this.props.item.selected && this.table.selected.length === this.table.maxSelectAmount) {
        this.$store.dispatch(GlobalActions.showDialog, {
          show: true,
          component: InfoDialog,
          meta: {
            title: __('company.views.risk-assessments.templates.dialog.max-select.title'),
            text: __('company.views.risk-assessments.templates.dialog.max-select.text'),
          },
        });
      }
      else {
        this.props.item.selected = !this.props.item.selected;
      }
    }
  }












import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import DataTableFilterObject from '@/shared/classes/components/data-table/data-table-filter-object'
import DataTableFilter from '@/shared/classes/components/data-table/data-table-filter'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import { FieldTypes } from '@/shared/components/form/field-types'
import _ from 'lodash'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import moment from 'moment'
import { getVersion } from '@/shared/helpers/version.helper'

@Component({
  methods: {
    __
  }
})
export default class ActiveFilters extends Vue {
  @Prop() filter!: DataTableFilterObject
  @Prop() clear!: () => void
  activeFilters: any[] = []

  mounted() {
    this.parseActiveFilters()
    this.$watch('$route', this.parseActiveFilters)
  }

  clearSingle(filter: DataTableFilter): void {
    let { query, name: string } = this.$router.currentRoute
    query = { ...query }

    delete query[filter.getQueryName()]
    this.$router.push({ name, query })
      .catch(() => {})
  }

  getValue(activeFilter: any): string {
    const filter: DataTableFilter | undefined = this.getFilter(activeFilter)
    if (! filter) return activeFilter.value

    if (filter.keyIsPrimary) {
      const field: SelectField | SearchableField | any = filter.field

      switch (field.type) {
        case FieldTypes.multipleSelect:
        case FieldTypes.multipleSearchable:
          let additional = 0
          const value = activeFilter.value.map((activeFilterValue: string) => {
            const found = field.items.find((item: any) => item.value === activeFilterValue)
            return found ? found.title : ''
          }).filter((activeFilterValue: string, index: number) => {
            if (index + 1 > filter.visibleValuesCount) {

              additional++
              return false
            }

            return true
          }).join(', ')

          if (additional > 0) return `${ value }, (+${ additional })`
          return value
        case FieldTypes.select:
        case FieldTypes.searchable:
          const foundField = field.items.find((item: any) => item.value === activeFilter.value)
          return foundField ? foundField.title : ''
        case FieldTypes.datePicker:
          return moment(field.value).format(getVersion() === 'no' ? 'DD-MM-YYYY' : 'YYYY-MM-DD')
        case FieldTypes.checkbox:
          return (field.value == true || field.value === 'true') ? __('filters-values.checkbox.true') : __('filters-values.checkbox.false')
      }
    } else {
      const field: SelectField | SearchableField | any = filter.field

      switch (field.type) {
        case FieldTypes.datePicker:
          return moment(field.value).format(getVersion() === 'no' ? 'DD-MM-YYYY' : 'YYYY-MM-DD')
      }
    }

    return activeFilter.value
  }

  getFilter(activeFilter: any): DataTableFilter | undefined {
    return this.filter.filters.find((filter: DataTableFilter) => filter.getQueryName() === activeFilter.key)
  }

  private parseActiveFilters(): void {
    this.activeFilters = _.cloneDeep(this.filter.filters
      .filter((filter: DataTableFilter) => filter.field.value && filter.field.value !== '')
      .map((filter: DataTableFilter) => ({
        key: filter.getQueryName(),
        value: filter.field.value,
      }))
    )
  }
}

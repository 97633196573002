





import { Component, Prop, Vue } from 'vue-property-decorator'
import DataTableBase from '@/shared/classes/components/data-table/data-table'

@Component
export default class DataTableTabs extends Vue {
  @Prop() table!: DataTableBase
}







import { Component } from 'vue-property-decorator'
import AbstractHeader from '@/shared/components/data-table/header-types/AbstractHeader'

@Component
export default class IconHeader extends AbstractHeader {}













import { Component, Prop, Vue } from 'vue-property-decorator'
import { GlobalActions } from '@/shared/store/global/global.actions'
import __ from '@/shared/helpers/__'

@Component({
  methods: { __ }
})
export default class InfoDialog extends Vue {
  @Prop() meta: any

  closeDialog() {
    this.$store.dispatch(GlobalActions.closeDialog)
  }

  async onOk() {
    const { onOk } = this.meta
    this.closeDialog()
    onOk && await onOk()
  }
}

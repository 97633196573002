








































import { Component, Prop, Vue } from 'vue-property-decorator'
import TextHeader from '@/shared/components/data-table/header-types/TextHeader.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeaderTypes, { dataTableHeaderMap } from '@/shared/classes/components/data-table/data-table-header-types'
import { GlobalActions } from '@/shared/store/global/global.actions'
import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'

@Component({
  components: {
    TextHeader
  }
})
export default class HeaderTypes extends Vue {
  @Prop() table!: DataTableBase
  @Prop() props!: any
  tooltipShow: boolean = false

  getComponentName(type: DataTableHeaderTypes): any {
    if (dataTableHeaderMap.hasOwnProperty(type)) {
      return dataTableHeaderMap[type]
    }
    return TextHeader
  }

  doubleClick(item: any) {
    const doubleClick = this.table.doubleClick

    doubleClick && doubleClick(item)
  }

  openQuickAccess(item: Model<IModelResponse>) {
    if (! this.table.quickAccess) return
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: this.table.quickAccess,
      maxWidth: 1100,
      persistent: true,
      meta: {
        table: this.table,
        item,
      }
    })
  }
}

import _ from 'lodash'
import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import DataTableFilterObject from '@/shared/classes/components/data-table/data-table-filter-object'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import DataTableFilter from '@/shared/classes/components/data-table/data-table-filter'
import can from '@/shared/helpers/can.helper'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import DataTableTab from '@/shared/classes/components/data-table/data-table-tab'
import IDataTableDefaultFilter from '@/shared/interfaces/data-table/data-table-default-filter.interface'
import IDataTableClasses from '@/shared/interfaces/data-table/data-table-classes.interface'
import SelectAction from '@/shared/classes/components/data-table/data-table-select-actions'
import roleCan from "@/shared/helpers/role-can.helper";

export default class DataTableBase {
  model!: Model<IModelResponse>
  data: any[] = []
  endpoint!: string
  coloredBorder!: (item: Model<IModelResponse>) => string
  headers: DataTableHeader[] = []
  defaultFilters: IDataTableDefaultFilter[] = []
  filter: DataTableFilterObject = new DataTableFilterObject()
  actions: DataTableAction[] = []
  hasFilter: boolean = true
  rowFilterInHeading: boolean = false
  loading: boolean = false
  paginationNotVisible: boolean = false
  isSortable: boolean = false
  multipleCheckbox: boolean = false
  quickAccess: any = null
  activeTab: number|null = null
  tabs!: DataTableTab[]
  classes: IDataTableClasses = {
    tableCard: '',
    table: ''
  }
  noPadding: boolean = false
  groupBy!: string|string[]
  visualGroupBy!: string|null
  updateUrl: boolean = true
  doubleClick: any = null
  selectActions!: SelectAction[]
  perPage: number = 100
  params: any = null
  groupComponent: any = null
  meta: any = null
  links: any = null
  topRightComponent: any = null
  expandedItemComponent: any = null
  expandedItemComponentMeta: any = {}
  groupDesc: boolean = true
  groupSelect: boolean = false
  onChange!: (items: any[]) => void
  maxSelectAmount: number | null = null

  setModel(model: any): DataTableBase {
    this.model = model
    return this
  }

  setEndpoint(endpoint: string): DataTableBase {
    this.endpoint = endpoint
    return this
  }

  setActiveTab(tab: number): this {
    this.activeTab = tab
    return this
  }

  setHeaders(headers: DataTableHeader[]): DataTableBase {
    this.headers = headers
    return this
  }

  addHeader(header: DataTableHeader): DataTableBase {
    this.headers = [...this.headers, header]
    return this
  }

  unshiftHeader(header: DataTableHeader): DataTableBase {
    this.headers = [header, ...this.headers]
    return this
  }

  setActions(actions: DataTableAction[]): DataTableBase {
    this.actions = actions.filter((action: DataTableAction) => {
      if (! action.permissions && !action.allowedForRole?.length) return true

      return can(action.permissions) && roleCan(action.allowedForRole)
    })

    return this
  }

  setFilters(filters: DataTableFilter[]): DataTableBase {
    this.filter.setFilters(filters)
    this.filter.filters = filters
    return this
  }

  setData(data: any[]): DataTableBase {
    this.data = data
    this.onChange && this.onChange(data)
    return this
  }

  setItem(entry: any): DataTableBase {
    const data = _.cloneDeep(this.data)
    const index = data.findIndex((item: Model<IModelResponse>) => item.uuid === entry.uuid)
    data[index] = entry
    this.setData(data)

    return this
  }

  unshiftItem(item: any): DataTableBase {
    this.data = [item, ...this.data]
    return this
  }

  addItem(item: any): DataTableBase {
    this.setData([...this.data, item])
    return this
  }

  removeItem(item: any): DataTableBase {
    this.setData([...this.data.filter((itemI: any) => itemI.uuid !== item.uuid)])
    return this
  }

  updateItem(item: any): this {
    const data: any = _.cloneDeep(this.data)
    const index: any = this.data.findIndex((itemI: any) => itemI.uuid === item.uuid)
    data[index] = item
    this.setData(data)
    return this
  }

  setPaginationNotVisible(paginationNotVisible: boolean): DataTableBase {
    this.paginationNotVisible = paginationNotVisible
    return this
  }

  setIsSortable(isSortable: boolean): DataTableBase {
    this.isSortable = isSortable
    return this
  }

  setHasFilter(hasFilter: boolean): DataTableBase {
    this.hasFilter = hasFilter
    return this
  }

  setRowFilterInHeading(rowFilterInHeading: boolean): this {
    this.rowFilterInHeading = rowFilterInHeading
    return this
  }

  setColoredBorder(coloredBorder: (item: any) => string): this {
    this.coloredBorder = coloredBorder
    return this
  }

  get selected(): Array<Model<IModelResponse>> {
    return this.data.filter((item: Model<IModelResponse>) => item.selected)
  }

  get someSelected(): boolean {
    return this.data.some((item: Model<IModelResponse>) => item.selected)
  }

  get selectedAll(): boolean {
    if (! this.data || this.data.length === 0) return false
    return this.data.every((item: Model<IModelResponse>) => item.selected)
  }

  set selectedAll(value: boolean) {
    this.data.map((item: Model<IModelResponse>) => item.selected = value)
  }

  setMultipleCheckbox(value: boolean): this {
    this.multipleCheckbox = value
    return this
  }

  setQuickAccess(quickAccess: any): this {
    this.quickAccess = quickAccess
    return this
  }

  setTabs(tabs: DataTableTab[]): this {
    this.tabs = tabs
    this.activeTab = this.activeTab ? this.activeTab : 0
    return this
  }

  setGroupBy(groupBy: string|string[], visualGroupBy: string|null = null): this {
    this.groupBy = groupBy
    this.visualGroupBy = visualGroupBy
    return this
  }

  setUpdateUrl(updateUrl: boolean): this {
    this.updateUrl = updateUrl
    return this
  }

  setDefaultFilters(defaultFilters: IDataTableDefaultFilter[]): this {
    this.defaultFilters = defaultFilters
    return this
  }

  setDoubleClick(doubleClick: any): this {
    this.doubleClick = doubleClick
    return this
  }

  setSelectActions(selectActions: SelectAction[]): this {
    this.selectActions = selectActions
    return this
  }

  setPerPage(perPage: number): this {
    this.perPage = perPage
    return this
  }

  setParams(params: any): this {
    this.params = params
    return this
  }

  setGroupComponent(groupComponent: any): this {
    this.groupComponent = groupComponent
    return this
  }

  setMeta(meta: any): this {
    this.meta = meta
    return this
  }

  setLinks(links: any): this {
    this.links = links
    return this
  }

  setTopRightComponent(component: any): this {
    this.topRightComponent = component
    return this
  }

  setOnChange(onChange: (items: any[]) => void): this {
    this.onChange = onChange
    return this
  }

  setExpandedItemComponent(component: any, meta: any = {}): this {
    this.expandedItemComponent = component
    this.expandedItemComponentMeta = meta
    return this
  }

  setGroupSelect(groupSelect: boolean): this {
    this.groupSelect = groupSelect
    return this
  }

  setGroupDesc(groupDesc: boolean): this {
    this.groupDesc = groupDesc
    return this
  }

  setMaxSelectAmount(maxSelectAmount: number | null): this {
    this.maxSelectAmount = maxSelectAmount
    return this
  }
}

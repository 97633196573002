





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Table from '@/shared/components/data-table/Table.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import Filters from '@/shared/components/data-table/Filters.vue'
import DataTableTabs from '@/shared/components/data-table/DataTableTabs.vue'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import DataTableFilter from '@/shared/classes/components/data-table/data-table-filter'
import SelectedRows from '@/shared/components/data-table/SelectedRows.vue'
import can from '@/shared/helpers/can.helper'
import SelectAction from '@/shared/classes/components/data-table/data-table-select-actions'
import RowFilters from '@/shared/components/data-table/RowFilters.vue'

@Component({
  components: { RowFilters, SelectedRows, DataTableTabs, Filters, Table },
  methods: { can },
})
export default class DataTable extends Vue {
  @Prop() table!: DataTableBase

  @Watch('table.activeTab')
  private handleActiveTabChanges(): void {
    this.refresh()
  }

  created() {
    this.table.setHeaders(
      this.table.headers.filter((header: DataTableHeader) => header.showCondition),
    )

    this.table.setFilters(
      this.table.filter.filters.filter((filter: DataTableFilter) => filter.showCondition),
    )
  }

  async refresh() {
    if (this.tableRef) return await this.tableRef.filter()
  }

  get selectActionsEnabled(): boolean {
    return this.table.selectActions && this.table.selectActions.some((selectAction: SelectAction) => selectAction.enabled)
  }

  get tableRef(): any {
    return this.$refs.table
  }
}












import { Component } from 'vue-property-decorator'
import AbstractHeader from '@/shared/components/data-table/header-types/AbstractHeader'
import { IStyleParser } from '@/shared/interfaces/data-table/data-table-classes.interface'

@Component
export default class TextHeader extends AbstractHeader {
  get parsedValue(): any {
    return (this.header.valueParser ? this.header.valueParser(this.value, this.props.item) : this.value) || this.header.defaultValue
  }

  get styleParser(): IStyleParser|null {
    if (! this.header.styleParser) return null
    if (! this.header.styleParser(this.value, this.props.item)) return null

    return this.header.styleParser(this.value, this.props.item)
  }

  get dotStyles(): any {
    return this.styleParser ? this.styleParser.dot : {}
  }

  get containerStyles(): any {
    return this.styleParser ? this.styleParser.container : {}
  }
}















import { Component, Vue, Watch } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import _ from 'lodash'

@Component({
  methods: {
    __
  }
})
export default class Search extends Vue {
  searchQuery: string = ''
  debouncedSearch!: () => void

  mounted() {
    this.setSearchValue()
    this.$watch('$route', this.setSearchValue)
    this.debouncedSearch = _.debounce(this.search, 300)
  }

  @Watch('searchQuery')
  onSearchQueryChange() {
    this.debouncedSearch()
  }

  search(): void {
    let { query, name: string } = this.$router.currentRoute
    query = { ...query }

    this.searchQuery === '' ? delete query.q : query.q = this.searchQuery
    query.page = String(1)

    this.$router.push({ name, query })
      .catch(() => {})
  }

  private setSearchValue(): void {
    const { query } = this.$router.currentRoute
    this.searchQuery = query.q ? String(query.q) : ''
  }
}





import { Component } from 'vue-property-decorator'
import AbstractHeader from './AbstractHeader'

@Component
export default class TextLinkHeader extends AbstractHeader {
  goTo(): void {
    this.$router.push(this.header.meta.getRawLocation(this.props.item)).catch(() => {})
  }
}

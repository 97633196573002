














import { Component, Prop, Vue } from 'vue-property-decorator'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import __ from '@/shared/helpers/__'

@Component({
  methods: {
    __
  }
})
export default class ColumnFilter extends Vue {
  @Prop() headers!: DataTableHeader[]

  get headersFilterable(): DataTableHeader[] {
    return this.headers.filter((header: DataTableHeader) => ! header.notFilterableColumn)
  }
}

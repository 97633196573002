import DataTableHeaderTypes from '@/shared/classes/components/data-table/data-table-header-types'
import { IStyleParser } from '@/shared/interfaces/data-table/data-table-classes.interface'

export default class DataTableHeader {
  type: DataTableHeaderTypes = DataTableHeaderTypes.text
  key!: string
  entryKey!: string
  valueClass!: string
  defaultValue!: any
  classes!: string
  text!: string
  width!: string
  align!: string
  value!: string
  component!: any
  meta: any = {}
  hasDot: boolean = false
  styleParser?: (value: any, item: any) => IStyleParser
  showCondition: boolean = true
  sortable: boolean = true
  notFilterableColumn: boolean = false
  visible: boolean = true
  tooltip: (value: any) => any = (value: any) => value
  valueParser: (value: any, item?: any) => any = (value: any) => value
  iconComponent!: any
  valueComponent!: any
  sort!: any

  setType(type: DataTableHeaderTypes): DataTableHeader {
    this.type = type
    return this
  }

  setSort(sort: any): this {
    this.sort = sort
    return this
  }

  setEntryKey(entryKey: string): DataTableHeader {
    this.entryKey = entryKey
    return this
  }

  setValueClass(valueClass: string): DataTableHeader {
    this.valueClass = valueClass
    return this
  }

  setDefaultValue(defaultValue: any): DataTableHeader {
    this.defaultValue = defaultValue
    return this
  }

  setText(text: string): DataTableHeader {
    this.text = text
    return this
  }

  setWidth(width: string): DataTableHeader {
    this.width = width
    return this
  }

  setAlign(align: string): DataTableHeader {
    this.align = align
    return this
  }

  setMeta(meta: any): DataTableHeader {
    this.meta = meta
    return this
  }

  setSortable(sortable: boolean): DataTableHeader {
    this.sortable = sortable
    return this
  }

  setValueParser(valueParser: (value: any, item?: any) => any): DataTableHeader {
    this.valueParser = valueParser
    return this
  }

  setClasses(classes: string): DataTableHeader {
    this.classes = classes
    return this
  }

  setKey(value: string): DataTableHeader {
    this.key = value
    this.value = value
    return this
  }

  setVisible(visible: boolean): DataTableHeader {
    this.visible = visible
    return this
  }

  setNotFilterableColumn(notFilterableColumn: boolean): DataTableHeader {
    this.notFilterableColumn = notFilterableColumn
    return this
  }

  setShowCondition(showCondition: boolean): this {
    this.showCondition = showCondition
    return this
  }

  setTooltip(tooltip: (value: any) => any): this {
    this.tooltip = tooltip
    return this
  }

  showIcon(iconComponent: any): this {
    this.iconComponent = iconComponent
    return this
  }

  setHasDot(hasDot: boolean): this {
    this.hasDot = hasDot
    return this
  }

  setStyleParser(parser: (value: any, item: any) => IStyleParser): this {
    this.styleParser = parser
    return this
  }

  setComponent(component: any): this {
    this.component = component
    return this
  }

  setValueComponent(component: any): this {
    this.valueComponent = component
    return this
  }
}

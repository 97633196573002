










































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DataOptions } from 'vuetify'
import Sortable, { SortableEvent } from 'sortablejs'
import http from '@/shared/http'
import __ from '@/shared/helpers/__'
import IResponse from '@/shared/interfaces/modules/response.interface'
import HeaderTypes from '@/shared/components/data-table/HeaderTypes.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import DataTableHeaderTypes from '@/shared/classes/components/data-table/data-table-header-types'
import { GlobalActions } from '@/shared/store/global/global.actions'
import IDataTableDefaultFilter from '@/shared/interfaces/data-table/data-table-default-filter.interface'
import { getQueryName } from '@/shared/helpers/query.helper'
import _ from 'lodash'
import GroupSelect from '@/shared/components/data-table/GroupSelect.vue'
import Model from "@/shared/classes/model";
import InfoDialog from "@/shared/components/dialogs/InfoDialog.vue";

@Component({
  components: {
    GroupSelect,
    HeaderTypes,
  },
  methods: {
    __,
  },
})
export default class Table extends Vue {
  @Prop() table!: DataTableBase
  rerender: boolean = false
  options: DataOptions | any = {}
  total: number = 0
  page: number = 1
  perPage: number = 100
  itemsPerPageOptions = [ 10, 20, 30, 50, 100 ]
  sortable!: Sortable
  sortableList: any[] = []
  groupBy: string | string[] | null = null
  perPageLocalStorage: string = 'perPageRoutes'
  key = 'multiple-select'
  groupState: any = {}

  created() {
    this.addActionBtn()
    this.setDefaults()
  }

  mounted() {
    this.setInitialSorts()
    this.$watch('$route', () => {
      if (!this.$route.query.hasOwnProperty('per_page')) {
        this.setDefaults()
        return
      }

      this.filter()
    })
    this.$watch('options', this.updateQuery, { deep: true })
    this.$watch('table.data', () => {
      this.sortableList = [ ...this.table.data ]
      if (this.total === 0) {
        this.total = this.table.data.length
      }
    })
    if (this.table.isSortable) this.initialSortable()
    this.groupBy = this.table.groupBy
  }

  filter(query: any = null) {
    if (!this.table.endpoint) {
      this.total = this.table.data.length
      return
    }
    if (!query) query = this.$router.currentRoute.query
    const searchParams = new URLSearchParams('')
    let url = this.table.endpoint

    if (this.table.activeTab !== null) searchParams.set('tab', this.table.tabs[this.table.activeTab].key)

    this.table.defaultFilters.forEach((defaultFilter: IDataTableDefaultFilter) => {
      searchParams.set(getQueryName(defaultFilter.operator, defaultFilter.key), defaultFilter.value)
    })

    Object.keys(query).forEach((key: string) => searchParams.set(key, query[key] as string))

    this.table.params && Object.keys(this.table.params).forEach((param: any) => {
      searchParams.set(param, _.get(this.table.params, param))
    })

    if (searchParams.toString().length > 0) url += `?${ searchParams.toString() }`

    if (url) this.table.loading = true

    return url && http.get(url)
      .then((response: IResponse<IModelResponse[]>) => {
        const { data } = response
        const model: any = this.table.model
        this.table.setData(data.data.map((item: IModelResponse) => {
          return new model(item)
        }))
        this.table.setMeta(data.meta)
        this.table.setLinks(data.links || null)
        this.total = data.meta.total
        this.table.loading = false
        this.rerender = !this.rerender
      })
  }

  private setDefaults() {
    let { query, name }: any = this.$router.currentRoute
    query = { ...query }

    let locallyStored = _.get(JSON.parse(localStorage.getItem(this.perPageLocalStorage) as string), name)
    if (!locallyStored) {
      locallyStored = this.perPage
      if (this.table.perPage !== this.perPage) locallyStored = this.table.perPage
      this.setItemsPerPageOnRoute(name, locallyStored)
    }

    _.set(query, 'per_page', locallyStored)

    if (!query.per_page) return

    this.perPage = query.per_page ? query.per_page : this.perPage

    this.page = Number(_.get(query, 'page', this.page))

    _.set(query, 't', new Date().getTime())

    this.$router.push({ name, query })
      .catch(() => {
      })
  }

  updateQuery() {
    const { page, itemsPerPage, sortBy, sortDesc } = this.options
    let { query, name }: any = this.$router.currentRoute
    query = { ...query }

    query.page = page
    query.per_page = itemsPerPage

    this.setItemsPerPageOnRoute(name, itemsPerPage)

    if (sortBy[0]) {
      const header = this.table.headers.find((item: DataTableHeader) => item.key === sortBy[0])
      if (this.table.groupBy) this.groupBy = (header && header.entryKey) ? header.entryKey : sortBy[0]
      query.sort_by = sortBy[0]
      query.order = sortDesc[0] ? 'asc' : 'desc'
    } else {
      if (this.table.groupBy) this.groupBy = this.table.groupBy
      delete query.sort_by
      delete query.order
    }

    if (!this.table.updateUrl) {
      this.filter(query)
      return
    }

    this.$router.push({ name, query })
      .catch(() => {
      })
  }

  private addActionBtn(): void {
    if (this.table.actions.length === 0) return

    if (this.table.multipleCheckbox) {
      this.table.unshiftHeader(
        new DataTableHeader()
          .setType(DataTableHeaderTypes.multipleCheckbox)
          .setKey('multiple-select')
          .setText('')
          .setWidth('50px')
          .setAlign('center')
          .setNotFilterableColumn(true)
          .setSortable(false),
      )
    }

    if (this.table.headers.find((header: DataTableHeader) => header.key === DataTableHeaderTypes.action)) return

    this.table.addHeader(
      new DataTableHeader()
        .setType(DataTableHeaderTypes.action)
        .setKey('action')
        .setText('')
        .setWidth('100px')
        .setAlign('center')
        .setNotFilterableColumn(true)
        .setSortable(false),
    )
  }

  get visibleHeaders(): DataTableHeader[] {
    return this.table.headers.filter((header: DataTableHeader) => header.visible)
  }

  private setItemsPerPageOnRoute(name: string, itemsPerPage: number): void {
    const perPageRoutes: object = JSON.parse(localStorage.getItem(this.perPageLocalStorage) as string) || {}

    _.set(perPageRoutes, name, itemsPerPage)

    localStorage.setItem(this.perPageLocalStorage, JSON.stringify(perPageRoutes))
  }

  private setInitialSorts(): void {
    let { query } = this.$router.currentRoute

    if (query.sort_by) {
      this.options.sortBy = [ query.sort_by ]
    }

    if (query.order) {
      this.options.sortDesc = [ query.order === 'asc' ]
    }
  }

  private initialSortable(): void {
    this.addSortableHeader()
    const table: any = this.$refs.table
    if (!table) return

    this.sortable = new Sortable(
      table.$el.getElementsByTagName('tbody')[0],
      {
        draggable: '.sortable-row',
        handle: '.sortable-handle',
        onEnd: this.reorder,
      },
    )
  }

  private addSortableHeader(): void {
    if (this.table.headers.find((header: DataTableHeader) => header.defaultValue === 'mdi-drag-horizontal-variant')) return
    this.table.unshiftHeader(
      (new DataTableHeader())
        .setType(DataTableHeaderTypes.icon)
        .setDefaultValue('mdi-drag-horizontal-variant')
        .setClasses('sortable-handle')
        .setText('')
        .setSortable(false),
    )
  }

  private reorder(event: SortableEvent): void {
    const movedItem = this.sortableList.splice(event.oldIndex || 0, 1)[0]
    this.sortableList.splice(event.newIndex || 0, 0, movedItem)

    http.post(`${ this.table.endpoint }/reordering`, this.sortableList.map((item: any) => item.uuid))
      .then(() => this.$store.dispatch(GlobalActions.showSnackBar, {
        type: 'success',
        message: __('components.table.reorder-success'),
      }))
  }

  get isNextPageAvailable(): boolean {
    if (this.table.links?.next) return true;
    if (this.table.links && this.table.links.next === null) return false;

    if (typeof this.table.meta?.total === 'number') {
      return this.table.meta?.total > (this.options.page * this.options.itemsPerPage);
    }

    return true;
  }

  get selectedAll(): boolean {
    if (!this.table.data || this.table.data.length === 0) return false
    return this.table.data.every((item: Model<IModelResponse>) => item.selected)
  }

  onSelectAll() {
    const allSelected = this.selectedAll;

    if (this.table.maxSelectAmount && !allSelected && this.table.data.length > this.table.maxSelectAmount) {
      this.$store.dispatch(GlobalActions.showDialog, {
        show: true,
        component: InfoDialog,
        meta: {
          title: __('company.views.risk-assessments.templates.dialog.max-select.title'),
          text: __('company.views.risk-assessments.templates.dialog.max-select.text'),
        },
      });
    }
    else {
      this.table.data.map((item: Model<IModelResponse>) => item.selected = !allSelected)
    }
  }
}

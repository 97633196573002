import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import __ from '@/shared/helpers/__'
import router from '@/shared/router'
import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'

export default class EditAction extends DataTableAction {
  constructor(isUuid = true) {
    super()
    this.setIcon('mdi-pencil')
    this.setTitle(__('components.table.actions.edit'))
    this.setAction((item: Model<IModelResponse>) => router.push({ path: `${ router.currentRoute.path }/${ isUuid ? item.uuid : item.id }/edit`}))
  }
}











import { Component } from 'vue-property-decorator'
import AbstractHeader from '@/shared/components/data-table/header-types/AbstractHeader'

@Component
export default class CheckboxHeader extends AbstractHeader {
  disabled: boolean = false

  async callAction(value: boolean) {
    this.disabled = true
    this.header.meta.action && await this.header.meta.action(value, this.props.item, this.props)
    this.disabled = false
  }
}

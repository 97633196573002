








import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import Client from '@/shared/modules/client/client.model'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { CompanyRoutes } from '@/shared/router/company'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import DataTableFilter, { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import permissions from '@/shared/helpers/permissions.helper'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import ClientService from '@/shared/modules/client/client.service'
import Role, { RoleLevel } from '@/shared/modules/role/role.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters';
import User from '@/shared/modules/user/user.model';
import router from '@/shared/router';

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class ClientsIndex extends Vue {
  table = new DataTableBase()
    .setModel(Client)
    .setEndpoint(`company/${ this.company.slug }/clients`)
    .setDoubleClick(this.editEmployee)
    .setHeaders([
      new DataTableHeader()
        .setKey('full_name')
        .setEntryKey('fullName')
        .setText(__('company.views.clients.index.table.columns.client-name')),
      // new DataTableHeader()
      //   .setKey('email')
      //   .setText(__('company.views.clients.index.table.columns.email')),
      new DataTableHeader()
        .setKey('phone')
        .setText(__('company.views.clients.index.table.columns.phone')),
      new DataTableHeader()
        .setKey('role.name')
        .setText(__('company.views.clients.index.table.columns.role'))
        .setSortable(false)
    ])
    .setFilters([
      new DataTableFilter()
        .setOperator(FilterOperators.in)
        .setKeyIsPrimary()
        .setField(
          new SelectField()
            .isMultiple()
            .setKey('role.uuid')
            .setTitle(__('company.views.clients.index.table.filters.role'))
            .loadItems({
              endpoint: '/roles',
              value: 'uuid',
              title: 'name',
              filters: [
                {
                  type: FilterOperators.equals,
                  name: 'level',
                  value: RoleLevel.client,
                }
              ],
            })
        )
    ])
    .setActions([
      new EditAction()
        .setPermissions([
          permissions.company.clients.update,
          permissions.company.clients.show,
        ]),
      new DeleteAction()
        .setPermissions([ permissions.company.clients.delete ])
        .setAction((client: Client) => this.$store.dispatch(GlobalActions.showDialog, {
          show: true,
          component: AreYouSureDialog,
          meta: {
            title: __('company.views.clients.index.table.actions.delete.title'),
            text: __('company.views.clients.index.table.actions.delete.text', { fullName: client.fullName }),
            onYes: () => ClientService.destroy(this.company.slug, client.uuid)
              .then(() => this.table.removeItem(client)),
          }
        }))
    ])

  editEmployee(client: Client) {
    if (this.user.hasDynamicPermissions([permissions.company.clients.update, permissions.company.clients.show])) {
      this.$router.push({ path: `${ router.currentRoute.path }/${ client.uuid }/edit`})
    }
  }

  navigateToCreate(): void {
    this.$router.push({ name: CompanyRoutes.clientsCreate })
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}

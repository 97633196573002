











































import { Component, Prop } from 'vue-property-decorator'
import can from '@/shared/helpers/can.helper'
import AbstractHeader from '@/shared/components/data-table/header-types/AbstractHeader'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'

@Component({
  methods: {
    can,
  }
})
export default class ActionHeader extends AbstractHeader {
  @Prop() table!: DataTableBase

  hasAtLeastOneAction(): boolean {
    return this.table.actions.some((action: DataTableAction) => this.hasPermission(action.permissions, this.props.item))
  }

  hasPermission(permissions: string[], item: Model<IModelResponse>): boolean {
    if (! permissions) return true

    return can(permissions, false, item)
  }
}

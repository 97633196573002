var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.rerender)?_c('div'):_vm._e(),_c('v-data-table',{ref:"table",class:_vm.table.classes.table,attrs:{"item-key":"uuid","headers":_vm.visibleHeaders,"items":_vm.table.data,"options":_vm.options,"loading":_vm.table.loading,"items-per-page":_vm.perPage,"header-props":{ sortByText: _vm.__('components.table.sort-by-text') },"page":_vm.page,"group-by":_vm.groupBy,"group-desc":_vm.table.groupDesc,"hide-default-footer":true,"expanded":_vm.table.selected},on:{"update:items":function($event){return _vm.$set(_vm.table, "data", $event)},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var groupBy = ref.groupBy;
var items = ref.items;
var headers = ref.headers;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [(_vm.table.groupSelect)?_c('GroupSelect',{attrs:{"table":_vm.table,"groupBy":groupBy,"group":group,"items":items}}):_vm._e(),_c('td',{staticClass:"font-weight-medium cursor-pointer",attrs:{"colspan":headers.length},on:{"click":toggle}},[_c('div',{staticClass:"w-100 d-flex justify-space-between align-center"},[_c('span',{domProps:{"textContent":_vm._s(_vm.table.visualGroupBy ? items[0][_vm.table.visualGroupBy] : items[0][groupBy])}}),(_vm.table.groupComponent)?_c(_vm.table.groupComponent,{tag:"component",attrs:{"group":group,"items":items,"table":_vm.table}}):_vm._e(),_c('v-icon',{class:{ 'mdi-rotate-180': ! isOpen }},[_vm._v("mdi-chevron-down")])],1)])]}},{key:"item",fn:function(props){return [_c('HeaderTypes',{attrs:{"table":_vm.table,"props":props}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(_vm.table.expandedItemComponent)?_c('td',{staticClass:"table-expanded-cell",attrs:{"colspan":headers.length}},[(_vm.table.expandedItemComponent)?_c(_vm.table.expandedItemComponent,{tag:"component",attrs:{"item":item,"meta":_vm.table.expandedItemComponentMeta}}):_vm._e()],1):_vm._e()]}},{key:"footer",fn:function(){return [(! _vm.table.paginationNotVisible)?_c('div',{staticClass:"table-footer"},[_c('div',{staticClass:"d-flex align-center mr-3"},[_c('div',{staticClass:"mr-2 caption"},[_vm._v(_vm._s(_vm.__('components.table.rows-per-page')))]),_c('v-select',{attrs:{"items":_vm.itemsPerPageOptions,"hide-details":""},model:{value:(_vm.options.itemsPerPage),callback:function ($$v) {_vm.$set(_vm.options, "itemsPerPage", $$v)},expression:"options.itemsPerPage"}})],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.options.page === 1},on:{"click":function () { return _vm.options.page--; }}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('h4',{staticClass:"mx-3"},[_vm._v(_vm._s(_vm.options.page))]),_c('v-btn',{attrs:{"icon":"","disabled":!_vm.isNextPageAvailable},on:{"click":function () { return _vm.options.page++; }}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)]):_vm._e()]},proxy:true}])},[_vm._l((_vm.table.headers),function(header){return (header.component)?_c('template',{slot:("header." + (header.key))},[_c(header.component,{tag:"component",attrs:{"header":header,"table":_vm.table,"refresh":_vm.filter}})],1):_vm._e()}),_c('template',{slot:("header." + _vm.key)},[_c('div',{class:{ 'bordered-header': _vm.table.coloredBorder }},[_c('v-checkbox',{staticClass:"table-multiple-select",attrs:{"value":_vm.selectedAll,"hide-details":"","indeterminate":_vm.table.someSelected},nativeOn:{"!click":function($event){$event.stopPropagation();return _vm.onSelectAll($event)}}})],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
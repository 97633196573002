







import { Component, Prop, Vue } from 'vue-property-decorator'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import { GlobalActions } from "@/shared/store/global/global.actions";
import __ from "@/shared/helpers/__";
import InfoDialog from "@/shared/components/dialogs/InfoDialog.vue";

@Component({})
export default class GroupSelect extends Vue {
  @Prop() group!: string
  @Prop() groupBy!: string
  @Prop() items!: any[]
  @Prop() table!: DataTableBase

  get indeterminate() {
    return this.items.some((item: any) => item.selected) && !this.selectedAll
  }

  get selectedAll(): boolean {
    if (!this.items || this.items.length === 0) return false
    return this.items.every((item: Model<IModelResponse>) => item.selected)
  }

  onClick() {
    if (this.table.maxSelectAmount && !this.selectedAll && this.table.selected.length + this.items.length > this.table.maxSelectAmount) {
      this.$store.dispatch(GlobalActions.showDialog, {
        show: true,
        component: InfoDialog,
        meta: {
          title: __('company.views.risk-assessments.templates.dialog.max-select.title'),
          text: __('company.views.risk-assessments.templates.dialog.max-select.text'),
        },
      });
    }
    else {
      this.items.map((item: Model<IModelResponse>) => item.selected = !item.selected)
    }
  }
}
















import { Component } from 'vue-property-decorator'
import AbstractHeader from '@/shared/components/data-table/header-types/AbstractHeader'
import _ from 'lodash'

@Component
export default class ImageWithTextHeader extends AbstractHeader {
  get imgUrl(): string | null {
    if (! this.header.meta) return null
    return _.get(this.props.item, this.header.meta.imgKey)
  }
}

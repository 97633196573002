import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import __ from '@/shared/helpers/__'
import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'

export default class DeleteAction extends DataTableAction {
  constructor() {
    super()
    this.setIcon('mdi-delete')
    this.setTitle(__('components.table.actions.delete'))
    this.setAction((item: Model<IModelResponse>) => {
      console.log('delete')
    })
  }

}

import DataTableFilter from '@/shared/classes/components/data-table/data-table-filter'

export default class DataTableFilterObject {
  hasClear: boolean = true
  filters: DataTableFilter[] = []

  setHasClear(hasClear: boolean): DataTableFilterObject {
    this.hasClear = hasClear
    return this
  }

  setFilters(filters: DataTableFilter[]): DataTableFilterObject {
    this.filters = filters
    return this
  }
}

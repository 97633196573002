import TextHeader from '@/shared/components/data-table/header-types/TextHeader.vue'
import IconHeader from '@/shared/components/data-table/header-types/IconHeader.vue'
import ActionHeader from '@/shared/components/data-table/header-types/ActionHeader.vue'
import MultipleCheckboxHeader from '@/shared/components/data-table/header-types/MultipleCheckboxHeader.vue'
import ImageHeader from '@/shared/components/data-table/header-types/ImageHeader.vue'
import TextLinkHeader from '@/shared/components/data-table/header-types/TextLinkHeader.vue'
import CountHeader from '@/shared/components/data-table/header-types/CountHeader.vue'
import CheckboxHeader from '@/shared/components/data-table/header-types/CheckboxHeader.vue'
import ImageWithTextHeader from '@/shared/components/data-table/header-types/ImageWithTextHeader.vue'
import StatusHeader from '@/shared/components/data-table/header-types/StatusHeader.vue';
import HoverHeader from '@/shared/components/data-table/header-types/HoverHeader.vue'

enum DataTableHeaderTypes {
  text = 'text',
  textLink = 'textLink',
  icon = 'icon',
  image = 'image',
  count = 'count',
  checkbox = 'checkbox',
  imageWithText = 'image-with-text',
  status = 'status',
  hover = 'hover',

  // System reserved
  action = 'action',
  multipleCheckbox = 'multiple-checkbox'
}

export default DataTableHeaderTypes

export const dataTableHeaderMap = {
  [DataTableHeaderTypes.text]: TextHeader,
  [DataTableHeaderTypes.textLink]: TextLinkHeader,
  [DataTableHeaderTypes.icon]: IconHeader,
  [DataTableHeaderTypes.image]: ImageHeader,
  [DataTableHeaderTypes.action]: ActionHeader,
  [DataTableHeaderTypes.multipleCheckbox]: MultipleCheckboxHeader,
  [DataTableHeaderTypes.count]: CountHeader,
  [DataTableHeaderTypes.checkbox]: CheckboxHeader,
  [DataTableHeaderTypes.imageWithText]: ImageWithTextHeader,
  [DataTableHeaderTypes.status]: StatusHeader,
  [DataTableHeaderTypes.hover]: HoverHeader,
}
